<template>
  <div id="table-alert">

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

		<template v-if="!loading">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="table-container">
            <table class="table table-custom table-borderless table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Mensaje</th>
                  <th scope="col" class="text-center">Segmentación</th>
                  <th scope="col" class="text-center">Estado</th>
                  <th scope="col" class="text-center">Fecha de emisión</th>
                  <th scope="col" class="text-center">Notificados</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(alerta, k) in alertPush_list"
                  :key="k"
                  :class="alerta.id_estado_fk == '1' ? '' : 'disabled'"
                >
                  <td class="th th-nombre">
                    <font-awesome-icon
                      class="name-icon ban"
                      v-if="alerta.id_estado_fk != '1'"
                      :icon="['fas', 'ban']"/>
                    {{
                      alerta.texto_notificacion.length > 50
                      ? alerta.texto_notificacion.substring(0, 49) + "..."
                      : alerta.texto_notificacion
                    }}
                  </td>
                  <td class="th th-segmentacion text-center">
                    {{ alerta.titulo_notificacion }}
                  </td>
                  <td class="th th-estado text-start text-center">
                    <span 
                      v-if="state_name(alerta) == 'Enviado'"
                      class="badge badge-custom badge-custom-green-light">
                      {{ state_name(alerta) }}
                    </span>
                    <span 
                      v-if="state_name(alerta) == 'Programado'"
                      class="badge badge-custom badge-custom-blue-light">
                      {{ state_name(alerta) }}
                    </span>
                    <span 
                      v-if="state_name(alerta) == 'Error'"
                      class="badge badge-custom badge-custom-red-light">
                      {{ state_name(alerta) }}
                    </span>
                  </td>
                  
                  <td class="th th-fecha-emision text-center">{{ emision_date(alerta) }}</td>
                  <td class="th th-notificados text-center" v-if="notified(alerta)">
                    <div class="progress">
                      <div 
                        :style="`width: ${ratio(alerta)}%`"
                        class="progress-bar"
                        :class="`${ratio(alerta)}` == '100' ? 'bg-success' : ''">
                        {{ ratio(alerta) }}%
                      </div>
                    </div>
                    <small class="text-muted">
                      <span class="badge border color-gray" v-if="`${ratio(alerta)}` < 20">
                        {{ ratio(alerta) }}%
                      </span>
                      {{ alerta.token_check }}/{{ alerta.token_totales }} usuarios
                    </small>
                  </td>
                  <td v-else>-</td>
                  <td class="th th-accion text-center">
                      <div 
                        class="dropdown dropdown-custom d-flex justify-content-center" 
                        @click="openOption(alerta.id_notificacion)">
                        <button 
                          class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                          type="button" 
                          data-bs-toggle="dropdown">
                          <font-awesome-icon icon="ellipsis-vertical"/>
                        </button>
                        <ul 
                          class="dropdown-menu"
                          v-click-outside="outOption">
                          <li>
                            <a 
                              v-if="stateBoolProgramada(alerta)"
                              @click="statusSwitch(alerta)"
                              class="dropdown-item" 
                              href="javascript:">
                              <font-awesome-icon
                                class="pe-2"
                                :class="alerta.id_estado_fk  == '1' ? 'color-main' : 'color-secondary'"
                                :icon="
                                  alerta.id_estado_fk == '1'
                                    ? ['fas', 'ban']
                                    : ['fas', 'check']"/>
                              {{ alerta.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                            </a>
                          </li>
                          <li>
                            <a 
                              class="dropdown-item" 
                              @click="details(alerta)"
                              href="javascript:">
                              <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                              Ver detalle
                            </a>
                          </li>
                          <li>
                            <a 
                              v-if="stateBoolProgramada(alerta)"
                              @click="openEditAlert(alerta.id_notificacion)"
                              class="dropdown-item" 
                              href="javascript:">
                              <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                              Editar
                            </a>
                          </li>
                          <li>
                            <a 
                              v-if="stateBoolEliminar(alerta)"
                              @click="questionDelete(alerta)"
                              class="dropdown-item" 
                              href="javascript:">
                              <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                              Eliminar
                            </a>
                          </li>
                          <li>
                            <a
                              v-if="stateBoolError(alerta)"
                              @click="resend(alerta)"
                              class="dropdown-item"
                              href="javascript:">
                              <font-awesome-icon
                                class="pe-2 color-main"
                                icon="paper-plane"/>
                              Reenviar
                            </a>
                          </li>
                        </ul>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-alerta" v-if="open_modal_details">
              <div class="modal-alerta__detalle">
                <div class="modal-alerta__up">
                  <h2 class="modal-alerta__title">Vista previa</h2>
                  <img
                    class="modal-alerta__close-modal"
                    src="@/assets/img/transporte/closeimg.svg"
                    alt=""
                    @click="open_modal_details = false"
                  />
                </div>
                <hr class="modal-alerta__separacion-secciones" />
                <p class="modal-alerta__text">
                  <span class="modal-alerta__text--negrita"> ID Notificación:</span>
                  {{ detail.id }}
                </p>
                <p class="modal-alerta__text">
                  <span class="modal-alerta__text--negrita"> Mensaje:</span>
                  {{ detail.texto }}
                </p>
                <p class="modal-alerta__text">
                  <span class="modal-alerta__text--negrita">Fecha de creación:</span>
                  {{ dateFormatymdhours(detail.fecha_emision) }}
                </p>
                <p class="modal-alerta__text">
                  <span class="modal-alerta__text--negrita">Fecha de emisión:</span>
                  {{ dateFormatymdhours(detail.fecha_notificacion) }}
                </p>
                <p class="modal-alerta__text">
                  <span class="modal-alerta__text--negrita">Estado:</span>
                  {{ detail.estado }}
                </p>
                <hr class="modal-alerta__separacion-secciones" />
                <div class="modal-alerta__boton-container">
                  <button
                    @click="open_modal_details = false"
                    class="modal-alerta__boton-cerrar"
                  >
                    Cerrar
                  </button>
                  <button
                    v-show="detail.estado == 'Programado'"
                    @click="editDetails"
                    class="modal-alerta__boton-editar"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>

            <SinResultados
            msg="Aceptar" 
            custom-click 
            @click="nuevaBusqueda" 
            v-if="(!loading || !filterLoading) && alertPush_list.length === 0"/>

          </div>
          
          <div class="users-list__pagination" v-if="pagination.page_count > 0">
            <paginate
              v-model="pagination.actual_page"
              :page-count="notificationsPagination.length"
              :page-range="pagination.page_range"
              :click-handler="pagination.click_handler"
              :prev-text="pagination.prev_text"
              :next-text="pagination.next_text"
              :container-class="pagination.container_class"
              :page-class="pagination.page_class"
            />
          </div>
          <Question
            v-if="question_modal_enable"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelEnable"
            @accept="acceptEnable"
          />
          <Question
            v-if="question_modal_delete"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelDelete"
            @accept="acceptDelete"
          />
          <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="closeStatus"
          />

        </div>
      </div>
    </template>

  </div>

</template>
<script>
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  import { mapState, mapActions } from "vuex";

  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
        Question, 
        Status, 

        //FF
        Loading,
        SinResultados
    },
    props:{
      notificationsPagination: {
        type: Array
      },
      estados:{
        type: Array
      },
      //FF
      filterLoading: {
        type: Boolean
      }    
    },

    data() {
      return {
        // Question
        question_modal_enable: false,
        question_modal_delete: false,
        question_modal_msg: "",
        // Status
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        // Details
        open_modal_details: false,
        open_modal_details_editar: false,
        // Acciones
        option: "",
        alerta_enable: null,
        detail: {
          id: "",
          texto: "",
          fecha_emision: "",
          fecha_notificacion: "",
          estado: "",
        },
        alerta_delete: null,
        pagination: {
          actual_page: 1,
          page_count: 1,
          page_range: 3,
          click_handler: this.paginacion,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class:"pagination-item"
        },
        loading: false
      };
    },

    computed: {
      ...mapState("alertPushModule", ["alertPush_list"]),
    },

    watch: {
      notificationsPagination() {
        this.pagination.actual_page = 1;
        this.getListaAlertas();
      }
    },
    methods: {
      ...mapActions("alertPushModule", [
        "deleteAlertPushAction",
        "cambioEstadoAlerta",
        "reenviarAlerta",
        "getListaAlertaPush"
      ]),
      nuevaBusqueda() {
        this.$emit("cleanFilter");
      },
      // Get según pagina
      async getListaAlertas(){
        this.loading = true;

        const pageObj = this.notificationsPagination.find(pageObj => pageObj.pagina === String(this.pagination.actual_page));
        const payload = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          id_usuario: this.$ls.get("user").id_usuario,
          id_notificacion: pageObj ? pageObj.notificaciones : []
        }
        const res = await this.getListaAlertaPush(payload);
        if (!res) {
          this.toastGenericError();
        }

        this.loading = false;
      },
      
      // control de paginación
      paginacion(page){
        this.pagination.actual_page = page;
        this.getListaAlertas();
      },

      // Estado
      state_name(alerta) {
        let found = this.estados.find(element => element.estado_notificacion == alerta.estado_notificacion);
        let data = "";
        if(Object.keys(found).length === 0){
          data = "Estado Error"
        }
        else{
          data = found.nombre_estado;
        }
        return data;
      },
      // Fecha de emisión
      emision_date(alerta){
        if(this.state_name(alerta) == "Programado" || this.state_name(alerta) == "Error"){
          return this.formatDateToUTC(alerta.fecha_notificacion);
        } else {
          return this.formatDateToUTC(alerta.fecha_creacion);
        }
      },
      date(ver_fecha) {
        if(ver_fecha == null) return "Por definir"; 
        let fecha = new Date(ver_fecha);
        let año = fecha.getFullYear().toString();
        let mes = fecha.getMonth() + 1;
        mes = mes.toString();
        let dia = fecha.getDate();
        let horas = fecha.getHours().toString();
        let minutos = fecha.getMinutes().toString();
        let horario = "AM";
        if (parseInt(horas) > 11) {
          horario = "PM";
          horas = parseInt(horas) - 12;
          horas = horas.toString();
        }
        if (mes.length == 1) mes = "0" + mes;
        if (horas.length == 1) horas = "0" + horas;
        if (horas == "00") horas = 12;
        if (minutos.length == 1) minutos = "0" + minutos;
        return ( dia + "/" + mes + "/" + año + ", " + horas + ":" + minutos + " " + horario);
      },
      // Notificados
      notified(alerta){
        if(alerta.token_check != null && alerta.token_totales != null){
          return true;
        } else {
          return false;
        }
      },
      ratio(alerta) {
        let vistos = parseInt(alerta.token_check);
        let total = parseInt(alerta.token_totales);
        if (total == 0) { return "0" }
        let porciento = (vistos / total) * 100;
        porciento = porciento.toFixed(2);
        porciento = parseFloat(porciento);
        return porciento;
      },
      // Acciones
      openOption(id) {
        setTimeout(() => {
          this.option = id;
        }, 10);
      },
      outOption() {
        if (this.option != "") {
          this.option = "";
        }
      },
      // Habilitar/deshabilitar
      stateBoolProgramada(alerta) {
        if (this.state_name(alerta) == "Programado") {
          return true;
        } else {
          return false;
        }
      },
      stateBoolError(alerta) {
        if (this.state_name(alerta) == "Error") {
          return true;
        } else {
          return false;
        }
      },
      stateBoolEliminar(alerta) {
        if (
          this.state_name(alerta) == "Programado" ||
          this.state_name(alerta) == "Error"
        ) {
          return true;
        } else {
          return false;
        }
      },
      statusSwitch(alerta) {
        if (alerta.id_estado_fk == "1") {
          this.changeStatus("3", alerta.id_notificacion);
        } else {
          this.questionEnable(alerta);
          console.log("Cambiar estado a 1", alerta);
        }
      },

      async changeStatus(status, id) {
        this.loading = true;
        let success_msg = status == "1" ? "activado" : "desactivado";
        //let err_msg = status == "1" ? "activar" : "desactivar";
        let data_ = {
          idNotificacion: id,
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idUsuario: this.$ls.get("user").id_usuario,
          idEstado: status,
        }
        this.cambioEstadoAlerta(data_)
        .then( res => {
          this.loading = false;
          if (res!=null) {
            this.$toast.open({
                  message: "Tú notificación ha cambiado de estado a "+success_msg,
                  type: "success",
                  duration: 6000,
                  position: "top-right",
            });
          }else{
            this.$toast.open({
                message: "El proceso realizado ha fallado, favor contactar a soporte@carpetres.cl",
                type: "error",
                duration: 0,
                position: "top-right",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.loading = false;
          this.$toast.open({
                message: "No se ha podido ejectuar el cambio de estado" +" favor contactar a soporte@carpetres.cl",
                type: "error",
                duration: 0,
                position: "top-right",
            });
        });
      },
      questionEnable(alerta) {
        this.alerta_enable = alerta;
        this.question_modal_msg =
          "Para habilitar una notificación debe volver a ingresar una fecha";
        this.question_modal_enable = true;
      },
      cancelEnable() {
        this.question_modal_enable = false;
      },
      acceptEnable() {
        this.$router.push({
          name: "push-edit",
          params: { id: this.alerta_enable.id_notificacion },
        });
      },
      // Vista previa
      details(alerta){
        this.detail = {
          id: alerta.id_notificacion,
          texto: alerta.texto_notificacion,
          fecha_emision: alerta.fecha_creacion,
          fecha_notificacion: alerta.fecha_notificacion,
          estado: this.state_name(alerta),
        };
        this.open_modal_details = true;
      },
      editDetails() {
        this.$router.push({
          name: "push-edit",
          params: { id: this.detail.id },
        });
      },
      // Editar
      openEditAlert(id_notificacion) {
        this.$router.push({
          name: "push-edit",
          params: { id: id_notificacion },
        });
      },
      // Eliminar
      questionDelete(alert) {
        this.alerta_delete = alert;
        this.question_modal_msg = "¿Estás seguro de eliminar esta alerta?";
        this.question_modal_delete = true;
      },
      cancelDelete() {
        this.question_modal_delete = false;
      },
      async acceptDelete() {
        this.loading = true;
        let data_ = {
          idNotificacion: this.alerta_delete.id_notificacion,
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idUsuario: this.$ls.get("user").id_usuario,
        }

        await this.deleteAlertPushAction(data_)
        .then( res => {
          this.loading = false;
          if (res!=null) {
            this.question_modal_delete = false;
            this.$toast.open({
                  message: "Notificación eliminada con éxito",
                  type: "success",
                  duration: 6000,
                  position: "top-right",
                });
          }
          else{
              this.question_modal_delete = false;
              this.$toast.open({
                message: "No se ha podido eliminar la notificación, intente nuevamente, favor contactar a soporte@carpetres.cl",
                type: "error",
                duration: 0,
                position: "top-right",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.loading = false;
          this.$toast.open({
                message: "No se ha podido eliminar la notificación, intente nuevamente, favor contactar a soporte@carpetres.cl",
                type: "error",
                duration: 0,
                position: "top-right",
            });
        });
      },
      // Reenviar
      async resend(alerta) {
        this.loading = true;
        let data_ = {
          idNotificacion: alerta.id_notificacion,
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idUsuario: this.$ls.get("user").id_usuario,
        }
        
        await this.reenviarAlerta(data_)
        .then((res) => {
          this.loading = false;
          if(res!=null){
            this.$toast.open({
              message: "Alerta reenviada con éxito",
              type: "success",
              duration: 6000,
              position: "top-right",
            });
          }
          else{
            this.$toast.open({
                message: "No se ha podido reenviar la notificación, intente nuevamente",
                type: "error",
                duration: 0,
                position: "top-right",
            });
          }
        })
          .catch((err) => {
            console.log("err", err);
            this.loading = false;
            this.$toast.open({
                message: "No se ha podido reenviar la notificación, favor contactar a soporte@carpetres.cl",
                type: "error",
                duration: 0,
                position: "top-right",
            });
          });
      },
      // Modal Status
      closeStatus() {
        this.open_modal_status = false;
      },
    },
  };
</script>