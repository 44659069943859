<template>
  <div id="filter-alert">
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
  
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Crea o revisa el estado de las alertas generadas en la plataforma.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/alerta-push/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Agregar alerta
        </router-link>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar notificación"
                v-model="filter.search"
                @keyup="filterSearch()"
                ref="searchInput"
                />
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="estados"
              label="nombre_estado"
              v-model="filter.estado"
              :clearable="false"
              placeholder="Filtrar por estado">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">

        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ListTable 
          :notificationsPagination="paginas" 
          :estados="estados"
          :filterLoading="loading"
          @cleanFilter="cleanFilter"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import SectionTabs from "../Section/SectionTabs.vue";
  import ListTable from "./ListTable.vue";
  import { mapActions, mapState } from "vuex";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"

  export default {
    components: { 
        SectionTabs, 
        ListTable,
        //FF
        InputDate
    },
    data() {
      return{
        tab_component: [
          {
            name: "Inicio",
            route: "push-list",
          },
          {
            name: "Agregar",
            route: "push-add",
          }
        ],
        alertas: [],

        //FF
        loading: false,
        filter: { 
          search: '',
          estado: ''
        },
        range: {
          date_start: null,
          date_end: null
        }
      };
    },
    computed: {
      ...mapState("alertPushModule", ["estados", "paginas"]),
    },
    async created(){
        await this.getEstados();
        await this.getNotificaciones();
    },
    methods: {
      ...mapActions("alertPushModule", ["getEstadosAlertaPush", "getPaginasAlertas"]),

      // Obtiene los estados de las alerta push
      async getEstados(){
        let data_ = {}
        await this.getEstadosAlertaPush(data_);
      },
      cleanFilter() {
        this.filter.search = '';
        this.filter.estado = '';
        
        this.range.date_start = null;
        this.range.date_end = null;        

        this.$refs.searchInput.focus();
        this.getNotificaciones();
      },
      async getNotificaciones(){
        this.loading = true;
        const payload = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          texto_notificacion: this.filter.search,
          estado_notificacion: this.filter.estado !== '' ? this.filter.estado.estado_notificacion : '0',
          fecha_inicio: this.range.date_start !== null ? moment(this.range.date_start).format('YYYY-MM-DD') : '',
          fecha_fin: this.range.date_end !== null ? moment(this.range.date_end).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
          limite: "20"
        };
        const res = await this.getPaginasAlertas(payload);
        if (!res) {
          this.toastGenericError();
        }
        
        this.loading = false;
      },
      // Filtro text
      filterSearch() {
        this.getNotificaciones();
      },
      //FF
      captureDateStart(date) {
        this.range.date_start = date;
      },
      captureDateEnd(date) {
        this.range.date_end = date;
      }
    },
    watch: {
      "filter.search"(value) {
        if(value === '') {
          this.getNotificaciones();
        }
      },
      "filter.estado"() {
        this.getNotificaciones();
      },
      "range.date_start"() {
        this.getNotificaciones();
      },
      "range.date_end"() {
        this.getNotificaciones();
      },
    }
  };
</script>